import { Snackbar } from "@material-ui/core";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Remove from "../../assets/images/remove.png";
import Config from "../../config.json";
import {
  addCompanyConfig,
  getCompanyConfigByCompanyId,
} from "../../services/adminApi";
import TextEditor from "../common/TextEditor";
import "../Stations/index.css";

const ModuleForm = (props) => {
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState({
    title: "Success",
    open: false,
  });
  const [logoImg, setLogoImg] = useState();
  const [logoImgError, setLogoImgError] = useState();
  const [watermarkRatio, setWatermarkRatio] = useState({
    height: 10,
    width: 20,
    width_landscap: 20,
    height_landscap: 10,
  });

  const [sponsorImg, setSponsorImg] = useState();
  const [sponsorImgError, setSponsorImgError] = useState();

  const [defaultProfileImg, setDefaultProfileImg] = useState();
  const [defaultProfileImgError, setDefaultProfileImgError] = useState();

  const [appHeaderImg, setAppHeaderImg] = useState();
  const [appHeaderImgError, setAppHeaderImgError] = useState();

  const [watermarkImg, setWatermarkImg] = useState();
  const [watermarkImgError, setWatermarkImgError] = useState();

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [homeUrl, setHomeUrl] = useState("");
  const [homeUrlError, setHomeUrlError] = useState("");

  const [mapUrl, setMapUrl] = useState("");
  const [mapUrlError, setMapUrlError] = useState("");

  const [photoFrameUrl, setPhotoFrameUrl] = useState("");
  const [photoFrameUrlError, setPhotoFrameUrlError] = useState("");

  const [logoUrl, setLogoUrl] = useState("");
  const [logoUrlError, setLogoUrlError] = useState("");

  const [sponsorUrl, setSponsorUrl] = useState("");
  const [sponsorUrlError, setSponsorUrlError] = useState("");

  const [backgroundColor, setBackgroundColor] = useState("#ebebeb");
  const [backgroundColorError, setBackgroundColorError] = useState("");

  const [textColor, setTextColor] = useState("#f5f5f5");
  const [textColorError, setTextColorError] = useState("");

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("#4287f5");
  const [buttonBackgroundColorError, setButtonBackgroundColorError] =
    useState("");

  const [buttonTextColor, setbuttonTextColor] = useState("#f5f5f5");
  const [buttonTextColorError, setbuttonTextColorError] = useState("");

  const [customInstruction, setCustomInstruction] = useState("");
  const [customInstructionPrivate, setCustomInstructionPrivate] = useState("");
  const [customInstructionSearch, setCustomInstructionSearch] = useState("");
  const [customInstructionError, setCustomInstructionError] = useState("");

  const [skipRegistration, setSkipRegistration] = useState(0);

  const [enableProfilePhotoEdit, setEnableProfilePhotoEdit] = useState(0);
  const [enableContactButton, setEnableContactButton] = useState(0);
  const [enableContactSearchButton, setEnableContactSearchButton] = useState(0);
  const [enableTelButton, setEnableTelButton] = useState(0);

  const [sortImages, setSortImages] = useState(0);

  const [appThemeColor, setAppThemeColor] = useState("#f5f5f5");

  const [companyId, setCompanyId] = useState();

  const [logoFlag, setLogoFlag] = useState(false);
  const [sponsorFlag, setSponsorFlag] = useState(false);
  const [defaultProfileFlag, setDefaultProfileFlag] = useState(false);
  const [appHeaderImgFlag, setAppHeaderImgFlag] = useState(false);
  const [watermarkImgFlag, setWatermarkImgFlag] = useState(false);
  const [htmlEditorFlag, setHtmlEditorFlag] = useState(false);
  const [customInstructionHtmlEditorFlag, setCustomInstructionHtmlEditorFlag] =
    useState(false);
  const [
    customPrivateInstructionHtmlEditorFlag,
    setCustomPrivateInstructionHtmlEditorFlag,
  ] = useState(false);
  const [
    customSearchInstructionHtmlEditorFlag,
    setCustomSearchInstructionHtmlEditorFlag,
  ] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [customInstructionEditorState, setCustomInstructionEditorState] =
    useState(EditorState.createEmpty());
  const [
    customInstructionPrivateEditorState,
    setCustomInstructionPrivateEditorState,
  ] = useState(EditorState.createEmpty());
  const [
    customInstructionSearchEditorState,
    setCustomInstructionSearchEditorState,
  ] = useState(EditorState.createEmpty());
  // const [editorState, setEditorState] = useState("");

  const [error, setError] = useState("");
  const [configId, setConfigId] = useState(0);

  // State for custom check in message
  const [checkInErrorEditorFlag, setCheckInErrorEditorFlag] = useState(false);
  const [checkInErrorEditorHtml, setCheckInErrorEditorHtml] = useState(
    EditorState.createEmpty()
  );

  const [checkInErrorEditorPlainText, setCheckInErrorEditorPlainText] =
    useState("");

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    setCompanyId(data.id);
    setCompanyName(data.company_name);
    getCompanyConfigHandler(data.id);
  }, []);

  const getCompanyConfigHandler = (id) => {
    getCompanyConfigByCompanyId(id).then(async (response) => {
      console.log("company Config form", response);
      debugger;
      if (response.data?.length) {
        let data = response.data[0];
        data?.id ? setConfigId(data.id) : setConfigId(0);
        data.event_logo ? setLogoImg(data.event_logo) : setLogoImg("");
        data.default_profile_photo
          ? setDefaultProfileImg(data.default_profile_photo)
          : setDefaultProfileImg("");
        data.app_header_img
          ? setAppHeaderImg(data.app_header_img)
          : setAppHeaderImg("");
        data.watermark_img
          ? setWatermarkImg(data.watermark_img)
          : setWatermarkImg("");
        data.description
          ? setDescription(data.description)
          : setDescription("");
        if (data.description) {
          const blocksFromHTML = convertFromHTML(data.description);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          setEditorState(EditorState.createWithContent(state));
        }
        data.home_url ? setHomeUrl(data.home_url) : setHomeUrl("");
        data.map_url ? setMapUrl(data.map_url) : setMapUrl("");
        data.photo_frame_url
          ? setPhotoFrameUrl(data.photo_frame_url)
          : setPhotoFrameUrl("");
        data.header_url ? setLogoUrl(data.header_url) : setLogoUrl("");
        data.footer_url ? setSponsorUrl(data.footer_url) : setSponsorUrl("");
        data.sponsors[data.sponsors.length - 1]
          ? setSponsorImg([data.sponsors[data.sponsors.length - 1].image_url])
          : setSponsorImg("");
        data.background_color
          ? setBackgroundColor(data.background_color)
          : setBackgroundColor("#ebebeb");
        data.text_color
          ? setTextColor(data.text_color)
          : setTextColor("#f5f5f5");
        data.button_background_color
          ? setButtonBackgroundColor(data.button_background_color)
          : setButtonBackgroundColor("#4287f5");
        data.button_text_color
          ? setbuttonTextColor(data.button_text_color)
          : setbuttonTextColor("#f5f5f5");
        data.app_theme_color
          ? setAppThemeColor(data.app_theme_color)
          : setAppThemeColor("#f5f5f5");
        data.custom_instruction
          ? setCustomInstruction(data.custom_instruction)
          : setCustomInstruction(data.custom_instruction);
        data.custom_instruction_private
          ? setCustomInstructionPrivate(data.custom_instruction_private)
          : setCustomInstructionPrivate(data.custom_instruction_private);
        data.custom_instruction_search
          ? setCustomInstructionSearch(data?.custom_instruction_search)
          : setCustomInstructionSearch(data?.custom_instruction_search);
        data.skip_page === 1
          ? setSkipRegistration(true)
          : setSkipRegistration(false);
        data.enable_profile_photo_edit === 1
          ? setEnableProfilePhotoEdit(true)
          : setEnableProfilePhotoEdit(false);
        data.sort_img ? setSortImages(data.sort_img) : setSortImages(0);
        data.contact_save_button
          ? setEnableContactButton(true)
          : setEnableContactButton(false);
        data.contact_save_search_button
          ? setEnableContactSearchButton(data.contact_save_search_button)
          : setEnableContactSearchButton(0);
        data.tel ? setEnableTelButton(data.tel) : setEnableTelButton(0);
        data.checkin_instruction
          ? setCheckInErrorEditorPlainText(data.checkin_instruction)
          : setCheckInErrorEditorPlainText(data.checkin_instruction);

        if (data.custom_instruction) {
          const blocksFromHTML = convertFromHTML(data.custom_instruction);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          setCustomInstructionEditorState(EditorState.createWithContent(state));
          if (data.height && data.width) {
            setWatermarkRatio({
              height: data.height,
              width: data.width,
              width_landscap: data.width_landscap,
              height_landscap: data.height_landscap,
            });
          }
        }
        if (data.custom_instruction_private) {
          const blocksFromHTML = convertFromHTML(
            data.custom_instruction_private
          );
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          setCustomInstructionPrivateEditorState(
            EditorState.createWithContent(state)
          );
        }
        if (data.checkin_instruction) {
          const blocksFromHTML = convertFromHTML(data.checkin_instruction);
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          setCheckInErrorEditorHtml(EditorState.createWithContent(state));
        }
        if (data?.custom_instruction_search) {
          const blocksFromHTML = convertFromHTML(
            data.custom_instruction_search
          );
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          setCustomInstructionSearchEditorState(
            EditorState.createWithContent(state)
          );
        }
      }
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const fieldChangeHandler = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case "logoImg":
        {
          let { file } = {
            file: null,
            base64URL: "",
          };
          file = e.target.files[0];
          getBase64(file)
            .then((result) => {
              file["base64"] = result;
              setLogoImg(result);
            })
            .catch((err) => {});
          setLogoFlag(true);
        }

        break;

      case "defaultProfileImg":
        {
          let { file } = {
            file: null,
            base64URL: "",
          };
          file = e.target.files[0];
          getBase64(file)
            .then((result) => {
              file["base64"] = result;
              setDefaultProfileImg(result);
            })
            .catch((err) => {});
          setDefaultProfileFlag(true);
        }

        break;

      case "appHeaderImg":
        {
          let { file } = {
            file: null,
            base64URL: "",
          };
          file = e.target.files[0];
          getBase64(file)
            .then((result) => {
              file["base64"] = result;
              setAppHeaderImg(result);
            })
            .catch((err) => {});
          setAppHeaderImgFlag(true);
        }

        break;

      case "watermarkImg":
        {
          let { file } = {
            file: null,
            base64URL: "",
          };
          file = e.target.files[0];
          getBase64(file)
            .then((result) => {
              file["base64"] = result;
              setWatermarkImg(result);
            })
            .catch((err) => {});
          setWatermarkImgFlag(true);
        }

        break;

      case "description":
        setDescription(value);

        break;

      case "sponsorImg":
        {
          let { file } = {
            file: null,
            base64URL: "",
          };
          file = e.target.files[0];
          getBase64(file)
            .then((result) => {
              file["base64"] = result;
              setSponsorImg([result]);
            })
            .catch((err) => {});
          setSponsorFlag(true);
        }

        break;

      case "homeUrl":
        setHomeUrl(value);
        break;

      case "mapUrl":
        setMapUrl(value);
        break;

      case "photoFrameUrl":
        setPhotoFrameUrl(value);
        break;

      case "logoUrl":
        setLogoUrl(value);
        break;

      case "sponsorUrl":
        setSponsorUrl(value);
        break;

      case "backgroundColor":
        setBackgroundColor(value);
        break;

      case "textColor":
        setTextColor(value);
        break;

      case "buttonBackgroundColor":
        setButtonBackgroundColor(value);
        break;

      case "buttonTextColor":
        setbuttonTextColor(value);
        break;

      case "appThemeColor":
        setAppThemeColor(value);
        break;

      case "customInstruction":
        setCustomInstruction(value);
        break;

      default:
    }
  };

  const previewHandler = () => {
    let tempObj = {
      logo: logoImg,
      description: description,
      editorState: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      customInstructionEditorState: draftToHtml(
        convertToRaw(customInstructionEditorState.getCurrentContent())
      ),
      sponsor: sponsorImg,
      defaultProfileImg: defaultProfileImg,
      appHeaderImg: appHeaderImg,
      homeUrl: homeUrl,
      mapUrl: mapUrl,
      photoFrameUrl: photoFrameUrl,
      logoUrl: logoUrl,
      sponsorUrl: sponsorUrl,
      backgroundColor: backgroundColor,
      textColor: textColor,
      buttonBackgroundColor: buttonBackgroundColor,
      buttonTextColor: buttonTextColor,
      appThemeColor: appThemeColor,
      logoFlag: logoFlag,
      sponsorFlag: sponsorFlag,
      defaultProfileFlag: defaultProfileFlag,
      appHeaderImgFlag: appHeaderImgFlag,
      customInstruction: customInstruction,
      skipRegistration: skipRegistration,
      enableProfilePhotoEdit: enableProfilePhotoEdit,
    };

    localStorage.setItem("companyPage", JSON.stringify(tempObj));
    props.history.push("/admin/preview");
  };

  const onEditorStateChange = (e) => {
    let des = draftToHtml(convertToRaw(e.getCurrentContent()))
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    setDescription(des);
    setEditorState(e);
  };

  const onCustomInstructionEditorChange = (e) => {
    let des = draftToHtml(convertToRaw(e.getCurrentContent()))
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    setCustomInstruction(des);
    setCustomInstructionEditorState(e);
  };
  const onCustomInstructionPrivateEditorChange = (e) => {
    let des = draftToHtml(convertToRaw(e.getCurrentContent()))
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    setCustomInstructionPrivate(des);
    setCustomInstructionPrivateEditorState(e);
  };
  const onCustomInstructionSearchEditorChange = (e) => {
    let des = draftToHtml(convertToRaw(e.getCurrentContent()))
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    setCustomInstructionSearch(des);
    setCustomInstructionSearchEditorState(e);
  };

  const handleHtmlEditor = () => {
    setHtmlEditorFlag(!htmlEditorFlag);
  };

  const handleCustomInstructionHtmlEditor = () => {
    setCustomInstructionHtmlEditorFlag(!customInstructionHtmlEditorFlag);
  };
  const handleCustomPrivateInstructionHtmlEditor = () => {
    setCustomPrivateInstructionHtmlEditorFlag(
      !customPrivateInstructionHtmlEditorFlag
    );
  };

  const handleCustomSearchInstructionHtmlEditor = () => {
    setCustomSearchInstructionHtmlEditorFlag(
      !customSearchInstructionHtmlEditorFlag
    );
  };

  const submitHandler = async (e, save) => {
    e.preventDefault();
    let tempObj = {
      id: configId,
      company_id: companyId,
      logo_base64: logoImg,
      app_header_img: appHeaderImg,
      home_url: homeUrl,
      map_url: mapUrl,
      photo_frame_url: photoFrameUrl,
      background_color: backgroundColor,
      button_background_color: buttonBackgroundColor,
      text_color: textColor,
      description: description,
      sponsor_logo_files: sponsorImg,
      footer_url: sponsorUrl,
      header_url: logoUrl,
      button_text_color: buttonTextColor,
      app_theme_color: appThemeColor,
      default_profile_photo: defaultProfileImg,
      custom_instruction: customInstruction,
      custom_instruction_private: customInstructionPrivate,
      custom_instruction_search: customInstructionSearch,
      skip_page: skipRegistration ? 1 : 0,
      enable_profile_photo_edit: enableProfilePhotoEdit ? 1 : 0,
      watermark_img: watermarkImg,
      sort_img: sortImages,
      contact_save_button: enableContactButton,
      contact_save_search_button: enableContactSearchButton,
      height: watermarkRatio.height,
      width: watermarkRatio.width,
      width_landscap: watermarkRatio.width_landscap,
      height_landscap: watermarkRatio.height_landscap,
      tel: enableTelButton,
      checkin_instruction: checkInErrorEditorPlainText,
    };

    await addCompanyConfig(tempObj)
      .then((response) => {
        if (!save) props.history.push("/admin/companyList");
        setMessage({ title: "Record successfully updated", open: "true" });
      })
      .catch((error) => {
        debugger;
        setError(error.message);
      });
  };

  const removeDefaultProfileHandler = (e) => {
    setDefaultProfileImg("");
    setDefaultProfileFlag(true);
  };

  const removeWatermarkHandler = (e) => {
    setWatermarkImg("");
    setWatermarkImgFlag(true);
  };

  const history = useHistory();
  const navigateToFooter = () => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    history.push("/admin/footer/" + data.id);
  };
  return (
    <div>
      <div className="icon">
        <NavLink to={"/admin/companyList"}>
          <button type="button" className="trans-btn mr-r10">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
                fill="#0099CC"
              />
              <path
                d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
                fill="#0099CC"
              />
            </svg>
          </button>
        </NavLink>
        <span className="">Dashboard</span>
        <span className=""> &#62; {companyName}</span>
      </div>

      <form action="">
        <div className="">
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">* Event Logo</label>
              <input
                name="logoImg"
                type="file"
                accept=".png, .jpg, .jpeg"
                id="logoImg"
                className="mr-l20 mr-t20"
                onChange={(event) => fieldChangeHandler(event)}
              />
              {logoImg && !logoFlag ? (
                <a
                  href={`${Config.imageApiUrl}/${logoImg}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to view previous photo
                </a>
              ) : null}
              {logoImgError !== "" ? (
                <p className="error-alert mr-b20">{logoImgError}</p>
              ) : null}
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Default Profile Image</label>
              <input
                name="defaultProfileImg"
                type="file"
                accept=".png, .jpg, .jpeg"
                id="defaultProfileImg"
                className="mr-l20 mr-t20"
                onChange={(event) => fieldChangeHandler(event)}
              />
              {defaultProfileImg && !defaultProfileFlag ? (
                <a
                  href={`${Config.imageApiUrl}/${defaultProfileImg}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to view previous photo
                </a>
              ) : null}
              <button
                type="button"
                className="trans-btn mr-l90"
                name="removeDefaultProfile"
                onClick={(e) => {
                  removeDefaultProfileHandler(e);
                }}
              >
                <img src={Remove} alt="" title="" style={{ width: "30px" }} />
                {/* <LockOpenIcon style={{ color: "#19375a" }} /> */}
              </button>
              {defaultProfileImgError !== "" ? (
                <p className="error-alert mr-b20">{defaultProfileImgError}</p>
              ) : null}
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">* App Header Image</label>
              <input
                name="appHeaderImg"
                type="file"
                accept=".png, .jpg, .jpeg"
                id="appHeaderImg"
                className="mr-l20 mr-t20"
                onChange={(event) => fieldChangeHandler(event)}
                // title=" "
              />
              {appHeaderImg && !appHeaderImgFlag ? (
                <a
                  href={`${Config.imageApiUrl}/${appHeaderImg}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to view previous photo
                </a>
              ) : null}
              {appHeaderImgError !== "" ? (
                <p className="error-alert mr-b20">{appHeaderImgError}</p>
              ) : null}
            </div>
          </div>
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Watermark Image</label>
              <input
                name="watermarkImg"
                type="file"
                accept=".png, .jpg, .jpeg"
                id="watermarkImg"
                className="mr-l20 mr-t20"
                onChange={(event) => fieldChangeHandler(event)}
                // title=" "
              />
              {watermarkImg && !watermarkImgFlag ? (
                <a
                  href={`${Config.imageApiUrl}/${watermarkImg}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click here to view previous photo
                </a>
              ) : null}
              <button
                type="button"
                className="trans-btn mr-l90"
                name="removeDefaultProfile"
                onClick={(e) => {
                  removeWatermarkHandler(e);
                }}
              >
                <img src={Remove} alt="" title="" style={{ width: "30px" }} />
                {/* <LockOpenIcon style={{ color: "#19375a" }} /> */}
              </button>
              {watermarkImgError !== "" ? (
                <p className="error-alert mr-b20">{watermarkImgError}</p>
              ) : null}
            </div>
          </div>

          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Enter height portrait photos</label>
            <input
              className="form-control mr-t20"
              type="number"
              name="hieght"
              value={watermarkRatio.height}
              onChange={(e) =>
                setWatermarkRatio({ ...watermarkRatio, height: e.target.value })
              }
            />
          </div>
          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Enter width portrait photos</label>
            <input
              className="form-control mr-t20"
              type="number"
              name="width"
              value={watermarkRatio.width}
              onChange={(e) =>
                setWatermarkRatio({ ...watermarkRatio, width: e.target.value })
              }
            />
          </div>
          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Enter height landscape photos</label>
            <input
              className="form-control mr-t20"
              type="number"
              name="height_landscap"
              value={watermarkRatio.height_landscap}
              onChange={(e) =>
                setWatermarkRatio({
                  ...watermarkRatio,
                  height_landscap: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Enter width landscape photos</label>
            <input
              className="form-control mr-t20"
              type="number"
              name="width_landscap"
              value={watermarkRatio.width_landscap}
              onChange={(e) =>
                setWatermarkRatio({
                  ...watermarkRatio,
                  width_landscap: e.target.value,
                })
              }
            />
          </div>
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">* Description</label>

              <div className="d-flex justify-content-end">
                Html Editor
                <label className="switch mr-b10 mr-l10">
                  <input
                    type="checkbox"
                    checked={htmlEditorFlag}
                    onChange={() => {
                      handleHtmlEditor();
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></div>
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              {htmlEditorFlag ? (
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => onEditorStateChange(e)}
                  toolbar={{
                    image: {
                      previewImage: true,
                      defaultSize: {
                        height: "250px",
                        width: "200px",
                      },
                    },
                  }}
                />
              ) : (
                <textarea
                  rows="100"
                  className="form-control"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              )}
            </div>
          </div>

          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Enter Home Url</label>
            <input
              className="form-control mr-t20"
              type="text"
              name="homeUrl"
              value={homeUrl}
              onChange={(e) => fieldChangeHandler(e)}
            />
            {homeUrlError !== "" ? (
              <p className="error-alert mr-b20">{homeUrlError}</p>
            ) : null}
          </div>
          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Enter Map Url</label>
            <input
              className="form-control mr-t20"
              type="text"
              name="mapUrl"
              value={mapUrl}
              onChange={(e) => fieldChangeHandler(e)}
            />
            {mapUrlError !== "" ? (
              <p className="error-alert mr-b20">{mapUrlError}</p>
            ) : null}
          </div>
          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Enter Phoro Frame Url</label>
            <input
              className="form-control mr-t20"
              type="text"
              name="photoFrameUrl"
              value={photoFrameUrl}
              onChange={(e) => fieldChangeHandler(e)}
            />
          </div>
          {photoFrameUrlError !== "" ? (
            <p className="error-alert mr-b20">{photoFrameUrlError}</p>
          ) : null}

          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Enter Logo Url</label>
            <input
              className="form-control mr-t20"
              type="text"
              name="logoUrl"
              value={logoUrl}
              onChange={(e) => fieldChangeHandler(e)}
            />
            {logoUrlError !== "" ? (
              <p className="error-alert mr-b20">{logoUrlError}</p>
            ) : null}
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">* Custom instruction</label>

              <div className="d-flex justify-content-end">
                Html Editor
                <label className="switch mr-b10 mr-l10">
                  <input
                    type="checkbox"
                    checked={customInstructionHtmlEditorFlag}
                    onChange={() => {
                      handleCustomInstructionHtmlEditor();
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: customInstruction,
                }}
              ></div>
            </div>
          </div>
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              {customInstructionHtmlEditorFlag ? (
                <Editor
                  editorState={customInstructionEditorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) =>
                    onCustomInstructionEditorChange(e)
                  }
                  toolbar={{
                    image: {
                      previewImage: true,
                      defaultSize: {
                        height: "250px",
                        width: "200px",
                      },
                    },
                  }}
                />
              ) : (
                <textarea
                  rows="100"
                  className="form-control"
                  value={customInstruction}
                  onChange={(e) => {
                    setCustomInstruction(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <TextEditor
            switchEditor={checkInErrorEditorFlag}
            setSwitchEditor={setCheckInErrorEditorFlag}
            htmlInstruction={checkInErrorEditorHtml}
            setHtmlInstruction={setCheckInErrorEditorHtml}
            plainText={checkInErrorEditorPlainText}
            setPlainText={setCheckInErrorEditorPlainText}
            title="Check-In Error Instructions For users "
          />
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">* Private tab Instructions For users</label>

              <div className="d-flex justify-content-end">
                Html Editor
                <label className="switch mr-b10 mr-l10">
                  <input
                    type="checkbox"
                    checked={customPrivateInstructionHtmlEditorFlag}
                    onChange={() => {
                      handleCustomPrivateInstructionHtmlEditor();
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: customInstructionPrivate,
                }}
              ></div>
            </div>
          </div>
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              {customPrivateInstructionHtmlEditorFlag ? (
                <Editor
                  editorState={customInstructionPrivateEditorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) =>
                    onCustomInstructionPrivateEditorChange(e)
                  }
                  toolbar={{
                    image: {
                      previewImage: true,
                      defaultSize: {
                        height: "250px",
                        width: "200px",
                      },
                    },
                  }}
                />
              ) : (
                <textarea
                  rows="100"
                  className="form-control"
                  value={customInstructionPrivate}
                  onChange={(e) => {
                    setCustomInstructionPrivate(e.target.value);
                  }}
                />
              )}
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Search Instructions </label>

              <div className="d-flex justify-content-end">
                Html Editor
                <label className="switch mr-b10 mr-l10">
                  <input
                    type="checkbox"
                    checked={customSearchInstructionHtmlEditorFlag}
                    onChange={() => {
                      handleCustomSearchInstructionHtmlEditor();
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: customInstructionSearch,
                }}
              ></div>
            </div>
          </div>
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              {customSearchInstructionHtmlEditorFlag ? (
                <Editor
                  editorState={customInstructionSearchEditorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) =>
                    onCustomInstructionSearchEditorChange(e)
                  }
                  toolbar={{
                    image: {
                      previewImage: true,
                      defaultSize: {
                        height: "250px",
                        width: "200px",
                      },
                    },
                  }}
                />
              ) : (
                <textarea
                  rows="100"
                  className="form-control"
                  value={customInstructionSearch}
                  onChange={(e) => {
                    setCustomInstructionSearch(e.target.value);
                  }}
                />
              )}
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30 d-flex">
              <label htmlFor=""> Footer image</label>
              <input
                name="sponsorImg"
                type="file"
                accept=".png, .jpg, .jpeg"
                id="photo"
                className="mr-l20 "
                onChange={(event) => fieldChangeHandler(event)}
              />
              {sponsorImg && !sponsorFlag ? (
                <div>
                  <button
                    className="s-blue-btn m-btn mr-l20 mr-r10"
                    id="previewButton"
                    onClick={() => setSponsorImg(null)}
                  >
                    Delete Image
                  </button>{" "}
                  <button
                    className="s-blue-btn m-btn mr-l20 mr-r10"
                    id="previewButton"
                    onClick={() =>
                      window.open(
                        `${Config.imageApiUrl}/${sponsorImg}`,
                        "_blank"
                      )
                    }
                  >
                    View Previous Image{" "}
                  </button>
                </div>
              ) : null}
              {sponsorImgError !== "" ? (
                <p className="error-alert mr-b20">{sponsorImgError}</p>
              ) : null}
            </div>
          </div>

          <div className="form-group gray-fill-input mr-b30">
            <label htmlFor="">Footer URL</label>
            <input
              className="form-control mr-t20"
              type="text"
              name="sponsorUrl"
              value={sponsorUrl}
              onChange={(e) => fieldChangeHandler(e)}
            />
            {sponsorUrlError !== "" ? (
              <p className="error-alert mr-b20">{sponsorUrlError}</p>
            ) : null}
          </div>

          <button
            className="s-blue-btn m-btn mr-l20 mr-r10"
            id="previewButton"
            onClick={navigateToFooter}
          >
            Setup Footer Slideshow{" "}
          </button>
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Choose Background color</label>
              <input
                className="mr-l20 mr-t20"
                type="color"
                id="backgroundColor"
                name="backgroundColor"
                value={backgroundColor}
                onChange={(event) => fieldChangeHandler(event)}
              />
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Choose Text color</label>
              <input
                className="mr-l20 mr-t20"
                type="color"
                id="textColor"
                name="textColor"
                value={textColor}
                onChange={(event) => fieldChangeHandler(event)}
              />
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Choose Button Background color</label>
              <input
                className="mr-l20 mr-t20"
                type="color"
                id="buttonBackgroundColor"
                name="buttonBackgroundColor"
                value={buttonBackgroundColor}
                onChange={(event) => fieldChangeHandler(event)}
              />
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Choose Button Text color</label>
              <input
                className="mr-l20 mr-t20"
                type="color"
                id="buttonTextColor"
                name="buttonTextColor"
                value={buttonTextColor}
                onChange={(event) => fieldChangeHandler(event)}
              />
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Skip Registration</label>
              <div>
                <label>Yes</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="skipRegistration"
                  checked={skipRegistration == 1 ? true : false}
                  value={true}
                  onChange={(event) => setSkipRegistration(true)}
                />
              </div>
              <div>
                <label>No</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="skipRegistration"
                  checked={skipRegistration == 0 ? true : false}
                  value={false}
                  onChange={(event) => setSkipRegistration(false)}
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Enable Profile Photo Edit</label>
              <div>
                <label>Yes</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="enableProfilePhotoEdit"
                  checked={enableProfilePhotoEdit == 1 ? true : false}
                  value={true}
                  onChange={(event) => setEnableProfilePhotoEdit(true)}
                />
              </div>
              <div>
                <label>No</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="enableProfilePhotoEdit"
                  checked={enableProfilePhotoEdit == 0 ? true : false}
                  value={false}
                  onChange={(event) => setEnableProfilePhotoEdit(false)}
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Sort Images</label>
              <div>
                <label>Newest One first</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="sortImages"
                  checked={sortImages == 0 ? true : false}
                  value={"0"}
                  onChange={(event) => setSortImages(event.target.value)}
                />
              </div>
              <div>
                <label>Oldest One first</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="sortImages"
                  checked={sortImages == 1 ? true : false}
                  value={"1"}
                  onChange={(event) => setSortImages(event.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">
                Enable Save Contact button{" "}
                <input
                  className="mr-l20 mr-t20"
                  type="checkbox"
                  name="enableContactButton"
                  checked={enableContactButton == 1 ? true : false}
                  onChange={(event) =>
                    setEnableContactButton(event.target.checked)
                  }
                />
              </label>
              {/* <div>
                <input
                  className="mr-l20 mr-t20"
                  type="checkbox"
                  name="enableProfilePhotoEdit"
                  checked={enableProfilePhotoEdit == 1 ? true : false}
                  value={true}
                  onChange={(event) => setEnableProfilePhotoEdit(true)}
                />
              </div> */}
            </div>
          </div>
          {/* <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">
                Enable Save Contact Search button{" "}
                <input
                  className="mr-l20 mr-t20"
                  type="checkbox"
                  name="enableContactButton"
                  checked={enableContactSearchButton == 1 ? true : false}
                  onChange={(event) =>
                    setEnableContactSearchButton(event.target.checked)
                  }
                />
              </label>
            </div>
          </div> */}

          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor=""> Enable Save Contact Search button </label>
              <div>
                <label>None</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="enableContactSearchButton"
                  checked={enableContactSearchButton == "0" ? true : false}
                  value={"0"}
                  onChange={(event) =>
                    setEnableContactSearchButton(event.target.value)
                  }
                />
              </div>
              <div>
                <label>Both</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="enableContactSearchButton"
                  checked={enableContactSearchButton == "1" ? true : false}
                  value={"1"}
                  onChange={(event) =>
                    setEnableContactSearchButton(event.target.value)
                  }
                />
              </div>
              <div>
                <label>Web Only</label>
                <input
                  className="mr-l20 mr-t20"
                  type="radio"
                  name="enableContactSearchButton"
                  checked={enableContactSearchButton == "2" ? true : false}
                  value={"2"}
                  onChange={(event) =>
                    setEnableContactSearchButton(event.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor=""> Enable Tel & SMS </label>
            </div>
            <div>
              <label>Tel</label>
              <input
                className="mr-l20 mr-t20"
                type="radio"
                name="tel"
                checked={enableTelButton == 0 ? true : false}
                value={0}
                onChange={(event) => setEnableTelButton(0)}
              />
            </div>
            <div>
              <label>SMS</label>
              <input
                className="mr-l20 mr-t20"
                type="radio"
                name="tel"
                checked={enableTelButton == 1 ? true : false}
                value={1}
                onChange={(event) => setEnableTelButton(1)}
              />
            </div>
          </div>

          {/* <div>
            <AddFooter />
          </div> */}
          {error !== "" ? (
            <p className="error-alert mr-b20 mr-l40">{error}</p>
          ) : null}

          <div className="add-btn mr-t50 mr-b100 pd-b50 d-flex justify-content-center ">
            {/* <button className="s-blue-btn m-btn mr-l20 mr-r10"
                            id="previewButton"
                            disabled={!logoImg && !sponsorImg && !defaultProfileImg && !appHeaderImg && !description}
                            onClick={() => previewHandler()}>
                            Preview</button> */}
            <button
              className="s-blue-btn m-btn mr-l20 mr-r10"
              id="previewButton"
              disabled={
                !logoImg &&
                !sponsorImg &&
                !defaultProfileImg &&
                !appHeaderImg &&
                !description
              }
              onClick={(e) => submitHandler(e)}
            >
              Submit
            </button>
            <button
              className="s-blue-btn m-btn mr-l20 mr-r10"
              id="previewButton"
              disabled={
                !logoImg &&
                !sponsorImg &&
                !defaultProfileImg &&
                !appHeaderImg &&
                !description
              }
              onClick={(e) => submitHandler(e, true)}
            >
              Update
            </button>
          </div>
        </div>
      </form>
      <Snackbar
        open={message.open}
        onClose={() => setMessage({ message: "", open: false })}
        autoHideDuration={2000}
        color="white"
        // TransitionComponent={transition}
        message={message.title}
      />
    </div>
  );
};

export default ModuleForm;
